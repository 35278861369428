<template lang="html">
    <div class="task">
        <!-- 第一行 搜索栏 -->
        <!-- <div class="handleBox flex flex-jc-c flex-ai-c" @keyup.enter="curr=1;getList()">
            <el-input v-model="search.searchValue" placeholder="搜索" size="small" class="input-with-select" style="width:400px" @change="handleClick">
                <el-button slot="append" icon="el-icon-search" @click="handleClick"></el-button>
            </el-input>
        </div> -->

        <div class="content">
            <div class="roleList">
                <ul>
                    <div v-for="(menu,index) in menuList" :key="index">
                        <li :class="{active:$route.path==menu.path}" @click="routerClick(menu.path)">
                            <div class="name">
                                <el-badge :value="menu.count==0?'':menu.count">
                                    {{menu.name}}
                                </el-badge>
                            </div>
                            <i class="el-icon-caret-right"></i>
                        </li>
                    </div>
                </ul>
            </div>
            <div class="permissionList">
                <router-view ref="subpage" :searchValue="search.searchValue" />
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
export default {
    data() {
        return {
            search: {
                searchValue: '', //搜索关键词
            },
            countData: [],
            menuList: [{
                name: '会员提现审核',
                count: '0',
                path: '/cashAudit',
            },{
                name: '开通会员卡审核',
                count: '0',
                path: '/memberCard',
            },
            //  {
            //     name: '积分兑换审核',
            //     path: '/integralAudit',
            //     count: '0',
            // }
            ],
            newList:[]
        }
    },
    mounted: function() {
        this.setNav();
    },
    watch:{
        '$root.auditCount':function() {
            this.setNav();
        }
    },
    methods: {
        routerClick: function(path){
           this.$router.push(path) 
        },
        
        setNav: function() {
            this.countData = this.$root.auditCount;
            for (var nav of this.menuList) {
                if (nav.path == '/cashAudit') {
                    nav.count = this.countData.withdraw
                } else if (nav.path == '/memberCard') {
                    nav.count = this.countData.user 
                }
            }
        },
        handleClick: function() {
            this.$refs['subpage'].getList()
        }
    }
}
</script>
